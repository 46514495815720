import React, { Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';
import Container from 'common/src/components/UI/Container';
import {
  Accordion,
  AccordionItem,
  AccordionTitle,
  AccordionBody,
  IconWrapper,
  OpenIcon,
  CloseIcon,
} from 'common/src/components/Accordion';
import { Icon } from 'react-icons-kit';
import { plus } from 'react-icons-kit/entypo/plus';
import { minus } from 'react-icons-kit/entypo/minus';
import FaqSectionWrapper, {
  faqSectionWrapper,
  ButtonWrapper,
  PricingButton,
}
from './faqSection.style';
import { useIntl, Link } from "gatsby-plugin-intl";

const FaqSection = ({
  sectionHeader,
  sectionTitle,
  sectionSubTitle,
  titleStyle,
  descriptionStyle,
  buttonFillStyle,
  lang
}) => {
  const Data = useStaticQuery(graphql`
    query {
      saasJson {
        Faq_EN {
          id
          title
          description
          expend
        }
        Faq_PT {
          id
          title
          description
          expend
        }
      }
    }
  `);
  const intl = useIntl();

  const faqComponent = (faqItem, index) => {
    return (
      <AccordionItem key={index} expanded={faqItem.expend}>
        <Fragment>
          <AccordionTitle>
            <Fragment>
              <Heading content={faqItem.title} {...titleStyle} />
              <IconWrapper>
                <OpenIcon>
                  <Icon icon={minus} size={18} />
                </OpenIcon>
                <CloseIcon>
                  <Icon icon={plus} size={18} />
                </CloseIcon>
              </IconWrapper>
            </Fragment>
          </AccordionTitle>
          <AccordionBody>
            <Text
              content={faqItem.description}
              {...descriptionStyle}
            />
          </AccordionBody>
        </Fragment>
      </AccordionItem>
    )
  }
  return (
    <FaqSectionWrapper id="faq_section">
      <Container>
        <Box {...sectionHeader}>
          <Text content={intl.formatMessage({id: "faq_subTitle"})} {...sectionSubTitle} />
          <Heading content={intl.formatMessage({id: "faq_title"})}  {...sectionTitle} />
        </Box>
        <Box className="row">
          <Accordion>
            <Fragment>
              { lang ==='en'?
                Data.saasJson.Faq_EN.map((faqItem, index) => (
                faqComponent(faqItem,index)
                ))
              :
                Data.saasJson.Faq_PT.map((faqItem, index) => (
                faqComponent(faqItem,index)
                ))
              }
            </Fragment>
          </Accordion>
          <PricingButton>
            <Link to="/contact">
              <Button
                title={intl.formatMessage({id: "banner_interested"})}
                {...buttonFillStyle}
              />
            </Link>
          </PricingButton>
        </Box>
      </Container>
    </FaqSectionWrapper>
  );
};

// FaqSection style props
FaqSection.propTypes = {
  sectionHeader: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  buttonWrapper: PropTypes.object,
  button: PropTypes.object,
  lang: PropTypes.string,
};

// FaqSection default style
FaqSection.defaultProps = {
  // section header default style
  lang:"en",
  sectionHeader: {
    mb: '56px',
  },
  // sub section default style
  sectionSubTitle: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#0582CA',
    mb: '5px',
  },
  // section title default style
  sectionTitle: {
    textAlign: 'center',
    fontSize: ['20px', '24px'],
    fontWeight: '400',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
  },
  // accordion title default style
  titleStyle: {
    fontSize: ['16px', '19px'],
    fontWeight: '400',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
  },
  // accordion description default style
  descriptionStyle: {
    fontSize: '15px',
    color: '#5d646d',
    lineHeight: '1.75',
    mb: '0',
    fontWeight: '400',
  },
  buttonWrapper: {
    mt: `${11}`,
    flexBox: true,
    justifyContent: 'center',
  },
  button: {
    title: 'EXPLORE FORUM',
    type: 'button',
    fontSize: `${2}`,
    fontWeight: '600',
    borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    colors: 'primary',
    height: `${4}`,
  },
  buttonFillStyle: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '600',
    color: 'white',
    borderRadius: '4px',
    pl: '10px',
    pr: '10px',
    mt: '40px',
    colors: 'primaryWithBg',
    width: '200px',
    maxWidth: '100%',
  },
};

export default FaqSection;
